import React, { useEffect } from "react";
import HosemedicaBanner from "../../components/Hosemedica";
import Whywe from "../../components/Whywe";
import OurRange from "../../components/OurRange";
import BuisnessOverview from "../../components/B-Overview";
import Blogs from "../../components/Blogs";
import Presence from "../../components/Presence";
import Products from "../../components/Products";
import GetInTouch from "../../components/GetInTouch";

const Index = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <HosemedicaBanner />
      <Whywe />
      <OurRange />
      <BuisnessOverview />
      <Blogs />
      <Presence />
      <Products />
      <GetInTouch />
    </div>
  );
};

export default Index;
