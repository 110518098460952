import React, { useEffect, useState } from "react";
import styles from "./Product.module.scss";
import arrow from "../../assets/blueArrow.png";
import Add from "../../assets/Add.png";
import Subtract from "../../assets/subtract.png";
import Search from "../../assets/Search.png";
import OutlineArrow from "../../assets/outlineArrow.png";
import { AllProducts } from "../../utils/constants";
import CommingSoon from "../../assets/comming.png";
import GrayOutline from "../../assets/grayOutline.png";

const Index = () => {
  const [showProduct, setShowProduct] = useState(false);
  const [showProductItems, setShowshowProductItems] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
  });

  const handleEvent = (id) => {
    setShowshowProductItems({
      ...showProductItems,
      [id]: !showProductItems[id],
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.main}>
      <div className={styles.actBtns}>
        <button className={styles.download}>
          Download list
          <img src={arrow} alt="Arrow Icon" />
        </button>
        <button className={styles.contact}>Contact Us</button>
      </div>
      <div className={styles.cardsDiv}>
        <div className={styles.singleCard}>
          <div className={styles.cardTop}>
            <p className={styles.title}>All Products</p>
            <img
              className={styles.addIcon}
              onClick={() => setShowProduct(!showProduct)}
              src={showProduct ? Subtract : Add}
              alt="Add-Icon"
            />
          </div>
          {showProduct && (
            <>
              <div className={styles.cardContent}>
                <div className={styles.contentTop}>
                  <div className={styles.left}>
                    <span>Show</span>
                    <div className={styles.numDiv}>
                      <span className={styles.num}>5</span>
                      <img src={arrow} alt="Arrow-Icon" />
                    </div>
                    <span>Entries</span>
                  </div>
                  <div className={styles.right}>
                    <img src={Search} alt="Search" />
                    <input type="text" placeholder="Search product" />
                  </div>
                </div>

                <div className={styles.contentHeader}>
                  <span>Sr. no.</span>
                  <span>Product Category</span>
                  <span>Item name</span>
                  <span>Packing type</span>
                  <span>Original packing</span>
                  <span></span>
                </div>

                {AllProducts.map((elem) => {
                  return (
                    <div key={elem?.id} className={styles.contentBody}>
                      <div className={styles.contentBodyDetail}>
                        <span>{elem?.id}.</span>
                        <span> {elem?.category}</span>
                        <span>{elem?.itemName}</span>
                        <span>{elem?.packingType} </span>
                        <span>{elem?.originalPacking}</span>
                        <div
                          onClick={() => handleEvent(elem?.id)}
                          className={styles.moreDiv}
                        >
                          <span className={styles.contentMore}>
                            {showProductItems?.[elem?.id] ? "Close " : "See"}{" "}
                            Compostion
                          </span>
                          <img
                            className={
                              showProductItems?.[elem?.id]
                                ? styles.outlineArrow
                                : styles.closeComp
                            }
                            src={OutlineArrow}
                            alt="Arrow"
                          />
                        </div>
                      </div>
                      {showProductItems?.[elem?.id] && (
                        <div className={styles.contentComp}>
                          <p>Compostion</p>
                          <p>{elem?.compostion}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className={styles.mobilecardContent}>
                <div className={styles.contentTop}>
                  <div className={styles.right}>
                    <img src={Search} alt="Search" />
                    <input type="text" placeholder="Search product" />
                  </div>
                </div>
                <p className={styles.rangename}>Ortho Range</p>
                {AllProducts.map((elem) => {
                  if (elem?.category === "Ortho") {
                    return (
                      <div key={elem?.id} className={styles.contentBody}>
                        <div className={styles.contentBodyDetail}>
                          <p>{elem?.itemName}</p>
                          <img
                            onClick={() => handleEvent(elem?.id)}
                            className={
                              showProductItems?.[elem?.id]
                                ? styles.outlineArrow
                                : styles.closeComp
                            }
                            src={GrayOutline}
                            alt="Arrow"
                          />
                        </div>
                        {showProductItems?.[elem?.id] && (
                          <div className={styles.contentComp}>
                            <div>
                              <p>Packing type</p>
                              <p>{elem?.packingType}</p>
                            </div>
                            <div>
                              <p>Original Pack</p>
                              <p>{elem?.originalPacking}</p>
                            </div>
                            <div>
                              <p>Composition </p>
                              <p>{elem?.compostion}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                  return null
                })}
                <p className={styles.rangename}>Gynae Range</p>
                {AllProducts.map((elem) => {
                  if (elem?.category === "Gynae") {
                    return (
                      <div key={elem?.id} className={styles.contentBody}>
                        <div className={styles.contentBodyDetail}>
                          <p>{elem?.itemName}</p>
                          <img
                            onClick={() => handleEvent(elem?.id)}
                            className={
                              showProductItems?.[elem?.id]
                                ? styles.outlineArrow
                                : styles.closeComp
                            }
                            src={GrayOutline}
                            alt="Arrow"
                          />
                        </div>
                        {showProductItems?.[elem?.id] && (
                          <div className={styles.contentComp}>
                            <div className={styles.mbContentDetail}>
                              <p>Packing type</p>
                              <p>{elem?.packingType}</p>
                            </div>
                            <div className={styles.mbContentDetail}>
                              <p>Original Pack</p>
                              <p>{elem?.originalPacking}</p>
                            </div>
                            <div className={styles.mbContentDetail}>
                              <p>Composition </p>
                              <p>{elem?.compostion}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                  return null
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className={styles.cardsDiv}>
        <div className={styles.singleCard}>
          <div className={styles.cardTop}>
            <p className={styles.title}>Ortho Range</p>
            <img className={styles.addIcon} src={Add} alt="Add-Icon" />
          </div>
        </div>
      </div>

      <div className={styles.cardsDiv}>
        <div className={styles.singleCard}>
          <div className={styles.cardTop}>
            <p className={styles.title}>IVF Portfolio</p>
            <img className={styles.addIcon} src={Add} alt="Add-Icon" />
          </div>
        </div>
      </div>

      <div className={styles.cardsDiv}>
        <div className={styles.singleCard}>
          <div className={styles.cardTop}>
            <p className={styles.title}>Oncology Drugs</p>
            <img
              className={styles.commingsoon}
              src={CommingSoon}
              alt="Add-Icon"
            />
          </div>
        </div>
      </div>

      <div className={styles.cardsDiv}>
        <div className={styles.singleCard}>
          <div className={styles.cardTop}>
            <p className={styles.title}>IVF Portfolio</p>
            <img
              className={styles.commingsoon}
              src={CommingSoon}
              alt="Add-Icon"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
