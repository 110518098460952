import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import styles from "./Layout.module.scss";
import GoBack from "../assets/GoBack.png";
import { useNavigate, useLocation } from "react-router-dom";
import { capitalize } from "../utils/constFunctions";
import closeIcon from "../assets/NavIcons/close.png";
import { useState } from "react";

const Index = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "auto";
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleClose = (path) => {
    navigate(path);
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className={styles.mobileNav}>
        <div className={isMenuOpen ? styles.navContent : styles.navClose}>
          <div className={styles.closeDiv}>
            <img onClick={toggleMenu} src={closeIcon} alt="cross Icon" />
          </div>
          <div className={styles.links}>
            <p onClick={() => handleClose("/")}>Home</p>
            <p onClick={() => handleClose("/about-us")}>About us</p>
            <p onClick={() => handleClose("/product-list")}>Products</p>
            <p>Downloads</p>
            <p>Careers</p>
          </div>
        </div>
      </div>
      <div>
        <Navbar toggleMenu={toggleMenu} />
        <div className={styles.mainUp}>
          {location?.pathname !== "/" && (
            <div className={styles.main}>
              <div className={styles.text}>
                <div className={styles.title}>
                  <h1>{capitalize(location?.pathname.slice(1))}</h1>
                  <div className={styles.gradient}></div>
                </div>
              </div>
              <div onClick={() => navigate("/")} className={styles.backDiv}>
                <img src={GoBack} alt="GoBack Icon" />
                <p>Go Back</p>
              </div>
            </div>
          )}

          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Index;
