import QualityIcon from "../assets/WhyWe/Quality.png";
import InovationIcon from "../assets/WhyWe/Inovation.png";
import ExperienceIcon from "../assets/WhyWe/Experience.png";
import GlobalIcon from "../assets/WhyWe/Global.png";
import CustomerIcon from "../assets/WhyWe/Customer.png";
import SustainabilityIcon from "../assets/WhyWe/Sustainability.png";
import whyWe from "../components/Whywe/whywe.module.scss";

import OrthoIcon from "../assets/Range/ortho.png";
import GynaeIcon from "../assets/Range/gynae.png";
import IVFIcon from "../assets/Range/ivf.png";
import OncologyIcon from "../assets/Range/oncology.png";
import rangeStyle from "../components/OurRange/OurRange.module.scss";

import Blog1 from "../assets/Blogs/1.png";
import Blog2 from "../assets/Blogs/2.png";
import Blog3 from "../assets/Blogs/1.png";

import Location from "../assets/Footer/location.png";
import Links from "../assets/Footer/links.png";
import Services from "../assets/Footer/services.png";
import Call from "../assets/NavIcons/footer_call.png";
import Email from "../assets/NavIcons/footer_mail.png";

import Linkedln from "../assets/NavIcons/light_LinkedIn.png";
import Facebook from "../assets/NavIcons/facebook.png";
import Twitter from "../assets/NavIcons/twitter.png";
import Instagram from "../assets/NavIcons/instagram.png";
import FooterTwitter from "../assets/NavIcons/footer_twitter.png";

import Mission from "../assets/About/Mission.png";
import Vision from "../assets/About/Vision.png";
import Values from "../assets/About/Values.png";
import Innovation from "../assets/About/Innovation.png";
import Growth from "../assets/About/Growth.png";
import Customer from "../assets/About/Customer.png";

export const WhyWeCards = [
  {
    id: 1,
    img: QualityIcon,
    cardTitle: "Quality",
    cardInfo:
      "We are committed to the highest standards of quality and safety in everything we do, from the development and manufacturing of our products to our customer service and support.",
    style: whyWe.card1,
  },
  {
    id: 2,
    img: InovationIcon,
    cardTitle: "Innovation",
    cardInfo:
      "We have a strong focus on research and development, continuously striving to develop and bring to market innovative products that meet the needs of patients and healthcare professionals.",
    style: whyWe.card2,
  },
  {
    id: 3,
    img: ExperienceIcon,
    cardTitle: "Experience",
    cardInfo:
      "With years of experience in the healthcare industry, we have built a wealth of knowledge and expertise that allows us to provide exceptional products and services to our customers and partners.",
    style: whyWe.card3,
  },
  {
    id: 4,
    img: GlobalIcon,
    cardTitle: "Global Presence",
    cardInfo:
      "We have a strong global network of distributors and partners, enabling us to reach patients and healthcare professionals around the world with our products and services.",
    style: whyWe.card3,
  },
  {
    id: 5,
    img: CustomerIcon,
    cardTitle: "Customer Focus",
    cardInfo:
      "We are dedicated to putting our customers first, listening to their needs and concerns, and providing personalized service and support to ensure their satisfaction.",
    style: whyWe.card2,
  },
  {
    id: 6,
    img: SustainabilityIcon,
    cardTitle: "Sustainability",
    cardInfo:
      "We are committed to sustainability and social responsibility, working to minimize our environmental impact and contribute positively to the communities we serve.",
    style: whyWe.card1,
  },
];

export const RangeCards = [
  {
    id: 1,
    img: OrthoIcon,
    title: "Ortho Range",
    style: rangeStyle.card1,
    comming: false,
  },
  {
    id: 2,
    img: GynaeIcon,
    title: "Gynae Range",
    style: rangeStyle.card2,
    comming: false,
  },
  {
    id: 3,
    img: IVFIcon,
    title: "IVF Portfolio",
    style: rangeStyle.card3,
    comming: true,
  },
  {
    id: 4,
    img: OncologyIcon,
    title: "Oncology Drugs",
    style: rangeStyle.card4,
    comming: true,
  },
];

export const BlogData = [
  {
    id: 1,
    img: Blog1,
    title: "Hosmedica’s Remdesivir and Supply chain management",
    detail:
      "The Pharma industry of India has gained exponential growth in the past decade...",
  },
  {
    id: 2,
    img: Blog2,
    title: "Hosmedica Remdesivir: The Corona Warriors of India",
    detail:
      "The COVID-19 pandemic will never be forgotten, but at the same time will not...",
  },
  {
    id: 3,
    img: Blog3,
    title: "How Hosmedica Remdesivir Aided the Country",
    detail:
      "During the second wave of the COVID-19 pandemic, India witnessed a sudden rise...",
  },
];

export const MobileFooter = [
  {
    title: "Address",
    name: "address",
    logo: Location,
    detail: [
      "Corporate Office : 1265, Sector 8-C, Chandigarh, India, Pincode: 160009",
    ],
  },
  {
    title: "Contact",
    name: "contact",
    logo: Call,
    detail: ["+91-95050 90895"],
  },
  {
    title: "Mail Us",
    name: "mail",
    logo: Email,
    detail: ["enquiry@hosmedica.in"],
  },
  {
    title: "Quick Links",
    name: "links",
    logo: Links,
    detail: [
      "Home",
      "About Us",
      "Products",
      "Careers",
      "Download",
      "Our Blogs",
      "Our Presence",
    ],
  },
  {
    title: "Services",
    name: "services",
    logo: Services,
    detail: [],
  },
];

export const SocialIcon = [
  { id: 1, logo: Linkedln, footerIcon: Linkedln, name: "Linkedln" },
  {
    id: 2,
    logo: Facebook,
    footerIcon: Facebook,
    name: "Facebook",
  },
  {
    id: 3,
    logo: Twitter,
    name: "Twitter",
    footerIcon: FooterTwitter,
  },
  {
    id: 4,
    logo: Instagram,
    footerIcon: Instagram,
    name: "Instagram",
  },
];

export const AboutCards = [
  {
    id: 1,
    icon: Mission,
    title: "Mission",
    detail:
      "Our mission is to deliver exceptional value to our customers by offering innovative solutions that meet their needs and exceed their expectations. We strive to make a positive impact on the lives of our customers, employees, and the communities we serve.",
  },
  {
    id: 2,
    icon: Vision,
    title: "Vision",
    detail:
      "At our company, our vision is to be a recognized leader and trusted partner in our industry. We strive to make a lasting and positive impact on the world around us",
  },
  {
    id: 3,
    icon: Values,
    title: "Values",
    detail:
      "At our pharmaceutical company, we are guided by a set of core values that shape our culture, guide our decision-making, and inform our interactions with customers, patients, and the broader community.",
  },
  {
    id: 4,
    icon: Innovation,
    title: "Innovation and Adaptability",
    detail:
      "We embrace innovation and continuously seek new ways to improve our products, services, and processes. We stay abreast of the latest industry trends and technological advancements to remain competitive and deliver cutting-edge solutions. Our ability to adapt to change allows us to thrive in a dynamic business environment.",
  },
  {
    id: 5,
    icon: Growth,
    title: "Continuous Learning and Growth",
    detail:
      "We strive for continuous learning and growth, both as individuals and as an organization. We foster a culture of curiosity, collaboration, and personal development, empowering our team members to reach their full potential. We believe that by nurturing our talents, we will drive innovation and success.",
  },
  {
    id: 6,
    icon: Customer,
    title: "Customer Relationship",
    detail:
      "As a customer-centric company, we prioritize building long-lasting relationships with our clients. We take the time to understand their unique requirements and provide personalized solutions that address their specific challenges. Our dedicated support team is always ready to assist and provide exceptional customer service.",
  },
];

export const AllProducts = [
  {
    id: 1,
    category: "Ortho",
    itemName: "CROSHMG 150 IU",
    packingType: "Tablets",
    originalPacking: "10x10",
    compostion:
      "MYO-INOSITO 550 MG+ BERBERINE HCL 277.25 MG+ D- CHIRO INOSITOL 12.5 MG + GRAPE SEED EXTRACT 4.25 MG+ CHROMIUM PICOLINATE 201.5 MG+ L-METHYL FOLATE1 MG TABLETS",
  },
  {
    id: 2,
    category: "Ortho",
    itemName: "CROSHMG 150 IU",
    packingType: "Tablets",
    originalPacking: "10x10",
    compostion:
      "MYO-INOSITO 550 MG+ BERBERINE HCL 277.25 MG+ D- CHIRO INOSITOL 12.5 MG + GRAPE SEED EXTRACT 4.25 MG+ CHROMIUM PICOLINATE 201.5 MG+ L-METHYL FOLATE1 MG TABLETS",
  },
  {
    id: 3,
    category: "Ortho",
    itemName: "CROSHMG 150 IU",
    packingType: "Tablets",
    originalPacking: "10x10",
    compostion:
      "MYO-INOSITO 550 MG+ BERBERINE HCL 277.25 MG+ D- CHIRO INOSITOL 12.5 MG + GRAPE SEED EXTRACT 4.25 MG+ CHROMIUM PICOLINATE 201.5 MG+ L-METHYL FOLATE1 MG TABLETS",
  },
  {
    id: 4,
    category: "Ortho",
    itemName: "CROSHMG 150 IU",
    packingType: "Tablets",
    originalPacking: "10x10",
    compostion:
      "MYO-INOSITO 550 MG+ BERBERINE HCL 277.25 MG+ D- CHIRO INOSITOL 12.5 MG + GRAPE SEED EXTRACT 4.25 MG+ CHROMIUM PICOLINATE 201.5 MG+ L-METHYL FOLATE1 MG TABLETS",
  },
  {
    id: 5,
    category: "Ortho",
    itemName: "CROSHMG 150 IU",
    packingType: "Tablets",
    originalPacking: "10x10",
    compostion:
      "MYO-INOSITO 550 MG+ BERBERINE HCL 277.25 MG+ D- CHIRO INOSITOL 12.5 MG + GRAPE SEED EXTRACT 4.25 MG+ CHROMIUM PICOLINATE 201.5 MG+ L-METHYL FOLATE1 MG TABLETS",
  },
  {
    id: 6,
    category: "Gynae",
    itemName: "Clarithromycin",
    packingType: "Tablets",
    originalPacking: "10x10",
    compostion:
      "MYO-INOSITO 550 MG+ BERBERINE HCL 277.25 MG+ D- CHIRO INOSITOL 12.5 MG + GRAPE SEED EXTRACT 4.25 MG+ CHROMIUM PICOLINATE 201.5 MG+ L-METHYL FOLATE1 MG TABLETS",
  },
  {
    id: 7,
    category: "Gynae",
    itemName: "Clarithromycin",
    packingType: "Tablets",
    originalPacking: "10x10",
    compostion:
      "MYO-INOSITO 550 MG+ BERBERINE HCL 277.25 MG+ D- CHIRO INOSITOL 12.5 MG + GRAPE SEED EXTRACT 4.25 MG+ CHROMIUM PICOLINATE 201.5 MG+ L-METHYL FOLATE1 MG TABLETS",
  },
];
