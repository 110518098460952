import React from "react";
import styles from "./OurRange.module.scss";
import OrthoIcon from "../../assets/Range/ortho.png";
import GynaeIcon from "../../assets/Range/gynae.png";
import IVFIcon from "../../assets/Range/ivf.png";
import OncologyIcon from "../../assets/Range/oncology.png";

const index = () => {
  return (
    <div className={styles.main}>
      <div className={styles.text}>
        <div className={styles.title}>
          <h1>Our Range</h1>
          <div className={styles.gradient}></div>
        </div>
        <p className={styles.para}>
          Our pharmaceutical company offers a comprehensive portfolio of
          high-quality products across multiple therapeutic areas
        </p>
      </div>
      <div className={styles.cardsDiv}>
        <div className={`${styles.singleCard} ${styles.card1}`}>
          <img src={OrthoIcon} alt="icon" />
          <p>Ortho Range</p>
        </div>

        <div className={`${styles.singleCard} ${styles.card2}`}>
          <img src={GynaeIcon} alt="icon" />
          <p>Gynae Range</p>
        </div>

        <div className={styles.outer}>
          <div className={`${styles.comming} ${styles.comming_left}`}>
            Comming Soon
          </div>
          <div className={`${styles.singleCard} ${styles.card3}`}>
            <img src={IVFIcon} alt="icon" />
            <p>IVF Portfolio</p>
          </div>
        </div>

        <div className={styles.outer}>
          <div className={`${styles.comming} ${styles.comming_right}`}>
            Comming Soon
          </div>
          <div className={`${styles.singleCard} ${styles.card4}`}>
            <img src={OncologyIcon} alt="icon" />
            <p>Oncology Drugs</p>
          </div>
        </div>
      </div>
      <button className={styles.btn}>See All Range</button>
    </div>
  );
};

export default index;
