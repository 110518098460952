import React from "react";
import styles from "./Footer.module.scss";
import Logo from "../../assets/NavIcons/logo.png";
import Mail from "../../assets/NavIcons/footer_mail.png";
import Call from "../../assets/NavIcons/footer_call.png";
import EmailIcon from "../../assets/contact/email.png";
import { useState } from "react";
import { MobileFooter, SocialIcon } from "../../utils/constants";
import Arrow from "../../assets/Footer/arrow.png";
import { useNavigate } from "react-router-dom";
import { Link as Scroll } from "react-scroll";

const Index = () => {
  const [data, setData] = useState({
    address: false,
    contact: false,
    mail: false,
    links: false,
    services: false,
  });

  const handleEvent = (name) => {
    setData({ ...data, [name]: !data[name] });
  };

  const navigate = useNavigate();

  return (
    <div className={styles.main}>
      <div className={styles.logoDiv}>
        <div className={styles.left}>
          <img src={Logo} alt="logo" />
        </div>
        <div className={styles.right}>
          <p>Follow us on</p>

          <div className={styles.icons}>
            {SocialIcon.map((elem) => {
              return (
                <img
                  key={elem?.id}
                  className={styles.icon}
                  src={elem?.footerIcon}
                  alt={elem.name}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.addressDiv}>
          <p className={styles.addTitle}>Address</p>
          <p className={styles.add}>
            Corporate Office : 1265, Sector 8-C, Chandigarh, India, Pincode:
            160009
          </p>
          <p className={styles.dirTitle}>Get Directions</p>
          <p className={styles.time}>Timing- 10:00 AM to 7:00 PM</p>
          <div className={styles.contactDetail}>
            <a href="tel:+91-95050 90895" className={styles.contactDiv}>
              <img className={styles.contactImg} src={Call} alt="" />
              <div className={styles.contactText}>
                <p>Call us</p>
                <p>+91-9505090895</p>
              </div>
            </a>
            <a href="mailto:enquiry@hosmedica.in" className={styles.contactDiv}>
              <img className={styles.contactImg} src={Mail} alt="" />
              <div className={styles.contactText}>
                <p>Mail us</p>
                <p>enquiry@hosmedica.in</p>
              </div>
            </a>
          </div>
        </div>

        <div className={styles.listDiv}>
          <Scroll to="home" smooth={true} duration={500} offset={-100}>
            <p onClick={() => navigate("/")} className={styles.list}>
              Home
            </p>
          </Scroll>
          <p onClick={() => navigate("/about-us")} className={styles.list}>
            About us
          </p>
          <p onClick={() => navigate("/product-list")} className={styles.list}>
            Products
          </p>
          <Scroll to="Downloads" smooth={true} duration={500} offset={-50}>
            <p onClick={() => navigate("/")} className={styles.list}>
              Downloads
            </p>
          </Scroll>
          <Scroll to="Blogs" smooth={true} duration={500} offset={0}>
            <p onClick={() => navigate("/")} className={styles.list}>
              Our Blogs
            </p>
          </Scroll>
          <Scroll to="Presence" smooth={true} duration={500} offset={0}>
            <p onClick={() => navigate("/")} className={styles.list}>
              Our Presence
            </p>
          </Scroll>
        </div>

        <div className={styles.mobileFooter}>
          {MobileFooter.map((item, id) => {
            return (
              <div key={id}>
                <div className={styles.singleDetail}>
                  <div className={styles.left}>
                    <img src={item.logo} alt="" />
                    <p>{item.title}</p>
                  </div>
                  <img
                    className={
                      data?.[item?.name]
                        ? `${styles.rotate}`
                        : `${styles.right}`
                    }
                    onClick={() => handleEvent(item?.name)}
                    src={Arrow}
                    alt=""
                  />
                </div>
                {data?.[item?.name] && (
                  <div key={id} className={styles.detail}>
                    {item?.detail.map((elem) => (
                      <p>{elem}</p>
                    ))}
                  </div>
                )}
              </div>
            );
          })}

          <p className={styles.followUs}>Follow us on</p>
          <div className={styles.mobileIconsDiv}>
            {SocialIcon.map((elem) => (
              <img key={elem?.id} src={elem.footerIcon} alt={elem?.name} />
            ))}
          </div>
        </div>

        <div className={styles.subscribeDiv}>
          <div className={styles.text}>
            <div className={styles.title}>
              <h1>Newsletter</h1>
              <div className={styles.gradient}></div>
            </div>
            <p>Subscribe to our newsletter</p>
          </div>
          <form action="">
            <div className={styles.inputDiv}>
              <img className={styles.inputIcon} src={EmailIcon} alt="" />
              <input type="text" placeholder="Email" required />
            </div>
            <div className={styles.sbtDiv}>
              <button type="submit">Subscribe</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Index;
