import React from "react";
import styles from "./Products.module.scss";
import DownloadIcon from "../../assets/download.png";

const index = () => {
  return (
    <div id="Downloads" className={styles.main}>
      <div className={styles.text}>
        <div className={styles.title}>
          <h1>Product & Brochure</h1>
          <div className={styles.gradient}></div>
        </div>
      </div>
      <div className={styles.textBox}>
        <div className={styles.box}>
          <p className={styles.box_title}>Product list</p>
          <p className={styles.para}>
            Our superior quality medicines are trusted and recommended by both
            medical experts as well as our customers. Click below to Download
            our complete Product list
          </p>
          <div className={styles.downloadBtn}>
            <img src={DownloadIcon} alt="Download Icon" />
            <p>Download Product List</p>
          </div>
        </div>

        <div className={styles.box}>
          <p className={styles.box_title}>Brochure</p>
          <p className={styles.para}>
            Having begun its journey in 2001, Health Biotech operates in 50+
            overseas destinations and is now one of the leading Pharma
            Manufacturing Companies in India.
          </p>
          <div className={styles.downloadBtn}>
            <img src={DownloadIcon} alt="Download Icon" />
            <p>Download Brochure</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
