import React from "react";
import styles from "./GetInTouch.module.scss";
import NameIcon from "../../assets/contact/name.png";
import EmailIcon from "../../assets/contact/email.png";
import PhoneIcon from "../../assets/contact/phone.png";
import CountryIcon from "../../assets/contact/country.png";
import ProductIcon from "../../assets/contact/product.png";
import MessageIcon from "../../assets/contact/message.png";
import ArrowDown from "../../assets/contact/downArrow.png";

const index = () => {
  return (
    <div className={styles.main}>
      <div className={styles.text}>
        <div className={styles.title}>
          <h1>Get in Touch</h1>
          <div className={styles.gradient}></div>
        </div>
        <p className={styles.para}>
          You can fill out our online contact form to send us a message directly
          from our website. We will respond to your inquiry as soon as possible.
        </p>
      </div>

      <div className={styles.formDiv}>
        <form>
          <div className={styles.inputDiv}>
            <img className={styles.inputIcon} src={NameIcon} alt="" />
            <input type="text" placeholder="Name" required />
          </div>
          <div className={styles.inputDiv}>
            <img className={styles.inputIcon} src={EmailIcon} alt="" />
            <input type="text" placeholder="Email" required />
          </div>
          <div className={styles.inputDiv}>
            <img className={styles.inputIcon} src={PhoneIcon} alt="" />
            <input type="text" placeholder="Mobile no." required />
          </div>
          <div className={styles.inputDiv}>
            <img className={styles.inputIcon} src={CountryIcon} alt="" />
            <input type="text" placeholder="Country" required />
            <img src={ArrowDown} alt="arrowdown" />
          </div>
          <div className={styles.inputDiv}>
            <img className={styles.inputIcon} src={ProductIcon} alt="" />
            <input type="text" placeholder="Product" required />
          </div>
          <div className={styles.inputDiv}>
            <img className={styles.inputIcon} src={MessageIcon} alt="" />
            <textarea type="text" placeholder="Message" required />
          </div>
          <button className={styles.sbtBtn}>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default index;
