import React from "react";
import styles from "./Presence.module.scss";
import BulletPoint from "../../assets/bulletPoint.png";
import Map from "../../assets/indiamap.png";
import { Fragment } from "react";
import StraightLine from "../../assets/StraightLine.png";

const states = [
  "Jammu & Kashmir",
  "Punjab",
  "Delhi",
  "Uttrakhand",
  "Uttar Pradesh",
  "Rajasthan",
  "Gujrat",
  "Maharastra",
];

const index = () => {
  return (
    <div id="Presence" className={styles.main}>
      <div className={styles.text}>
        <div className={styles.title}>
          <h1>Our Presence</h1>
          <div className={styles.gradient}></div>
        </div>
        <p className={styles.para}>
          Our company is proud to have a strong domestic presence within our
          home country. We operate in several regions and have built a loyal
          customer base that trusts our products and services.
        </p>
      </div>

      <div className={styles.mapContent}>
        <div className={styles.stateDetails}>
          {states.map((elem, id) => {
            return (
              <Fragment key={id}>
                <div className={styles.state}>
                  <img
                    src={BulletPoint}
                    alt="bulletPoint"
                    className={styles.bulletpoint}
                  />
                  <p className={styles.stateName}>{elem}</p>
                </div>
                {elem !== "Maharastra" && (
                  <img
                    src={StraightLine}
                    alt="StraightLine"
                    className={styles.straightline}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
        <div className={styles.indiaMap}>
          <img src={Map} alt="IndiaMap" />
        </div>
      </div>
    </div>
  );
};

export default index;
