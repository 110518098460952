import React, { useEffect } from "react";
import styles from "./About.module.scss";
import { AboutCards } from "../../utils/constants";

const Index = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  return (
    <div className={styles.main}>
      <div className={styles.cardsDiv}>
        {AboutCards.map((elem) => {
          return (
            <div key={elem?.id} className={styles.singleCard}>
              <img className={styles.icon} src={elem?.icon} alt={elem?.icon} />
              <div className={styles.title}>
                <h1>{elem?.title}</h1>
                <div className={styles.gradient}></div>
              </div>
              <p className={styles.detail}>{elem?.detail}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Index;
