import { createBrowserRouter } from "react-router-dom";
import Home from './pages/Home'
import AboutUs from './pages/About'
import ProductList from './pages/Product'
import Layout from './Layout'

export const router=createBrowserRouter([
    {
        path:'/',
        element:<Layout/>,
        children:[
            {
                path:'/',
                element:<Home/>
            },
            {
                path:'/about-us',
                element:<AboutUs/>
            },
            {
                path:'/product-list',
                element:<ProductList/>
            },
        ]
    },
])