import React from "react";
import styles from "./Blogs.module.scss";
import { BlogData } from "../../utils/constants";

const index = () => {
  return (
    <div id="Blogs" className={styles.main}>
      <div className={styles.text}>
        <div className={styles.title}>
          <h1>Our Blogs</h1>
          <div className={styles.gradient}></div>
        </div>
      </div>

      <div className={styles.cardsDiv}>
        {BlogData.map((elem) => {
          return (
            <div key={elem?.id} className={styles.outerCard}>
              <div className={styles.singleCard}>
                <img src={elem?.img} alt="" />
                <div className={styles.cardTitle}>{elem?.title}</div>
                <div className={styles.detail}>
                  {elem?.detail}
                  <span>Read more</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default index;
