import React from "react";
import styles from "./whywe.module.scss";
import { WhyWeCards } from "../../utils/constants";

const index = () => {
  return (
    <div className={styles.whywe}>
      <div className={styles.text}>
        <div className={styles.title}>
          <h1>Why We ?</h1>
          <div className={styles.gradient}></div>
        </div>
        <p className={styles.para}>
          There are several reasons why our pharmaceutical company stands out in
          the healthcare industry and why we are the ideal partner for patients,
          healthcare professionals, and distributors alike. Here are some of the
          key reasons why you should choose us:
        </p>
      </div>

      <div className={styles.cardsDiv}>
        {WhyWeCards.map((elem) => {
          return (
            <div
              key={elem?.id}
              className={`${styles.singleCard} ${elem?.style}`}
            >
              <img src={elem?.img} alt={elem?.cardTitle} />
              <p className={styles.abtCard}>{elem?.cardTitle}</p>
              <p className={styles.cardInfo}>{elem?.cardInfo}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default index;
