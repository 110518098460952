import React from "react";
import styles from "./Navbar.module.scss";
import Logo from "../../assets/NavIcons/logo.png";
import Call from "../../assets/NavIcons/call.png";
import Mail from "../../assets/NavIcons/mail.png";
import Facebook from "../../assets/NavIcons/facebook.png";
import Instagram from "../../assets/NavIcons/instagram.png";
import Linkedln from "../../assets/NavIcons/linkedln.png";
import Twitter from "../../assets/NavIcons/twitter.png";
import Hamburger from "../../assets/NavIcons/hamburger.png";
import { useNavigate } from "react-router-dom";

const Index = (props) => {
  const navigate = useNavigate();
  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <img onClick={() => navigate("/")} src={Logo} alt="logo" />
      </div>
      <div className={styles.right}>
        <div className={styles.contactDiv}>
          <img src={Call} alt="Call" />
          <div className={styles.detailOuter}>
            <p>Call us</p>
            <a href="tel:+91-95050 90895">+91-95050 90895</a>
          </div>
        </div>
        <div className={styles.contactDiv}>
          <img src={Mail} alt="Call" />
          <div className={styles.detailOuter}>
            <p>Mail us</p>
            <a href="mailto:enquiry@hosmedica.in" className={styles.mailId}>
              enquiry@hosmedica.in
            </a>
          </div>
        </div>
        <div className={styles.socialsDiv}>
          <p>Follow us on</p>
          <div className={styles.icons}>
            <img className={styles.icon} src={Linkedln} alt="Linkedln" />
            <img className={styles.icon} src={Facebook} alt="Facebook" />
            <img className={styles.icon} src={Twitter} alt="Twitter" />
            <img className={styles.icon} src={Instagram} alt="Instagram" />
          </div>
        </div>
      </div>
      <div className={styles.hamburger} onClick={props?.toggleMenu}>
        <img src={Hamburger} alt="Hamburger" />
      </div>
    </div>
  );
};

export default Index;
