import React from "react";
import styles from "./Hosemedica.module.scss";
import ArrowIcon from "../../assets/arrowIcon.png";
import BlueArrow from '../../assets/blueArrow.png';
import Search from '../../assets/Search.png'
import { Link } from "react-router-dom";

const index = () => {
  return (
    <div id="home" className={styles.main}>
      <div className={styles.links}>
        <div>
          <p className={styles.link}>Home</p>
        </div>
        <div>
          <Link className={styles.link} to={'/about-us'}>About us</Link>
        </div>
        <div className={styles.more}>
          <Link to={'/product-list'} className={styles.link}>Products</Link>
          <img src={ArrowIcon} alt="ArrowIcon" />
        </div>
        <div className={styles.more}>
          <p className={styles.link}>Downloads</p>
          <img src={ArrowIcon} alt="ArrowIcon" />
        </div>
        <div>
          <p className={styles.link}>Careers</p>
        </div>
      </div>
      <div className={styles.middleBox}>
        <h1 className={styles.title}>Hosmedica</h1>
        <p className={styles.caption}>”Your Trusted Pharmacy"</p>
        <p className={styles.para}>
          Welcome to our pharmaceutical landing page, where you can find a wide
          range of effective solutions for your healthcare needs. Our company is
          committed to providing high-quality pharmaceutical products that are
          designed to improve your health and well-being.
        </p>
        <div className={styles.inputBox}>
            <div className={styles.left}>
                <img src={Search} alt="Search" />
                <input type="text" placeholder="Search Product" />
            </div>
            <div className={styles.right}>
                <img src={BlueArrow} alt="Arrowicon" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default index;
