import React from "react";
import styles from "./B-Overview.module.scss";

const index = () => {
  return (
    <div className={styles.main}>
      <div className={styles.text}>
        <div className={styles.title}>
          <h1>Buisness Overview</h1>
          <div className={styles.gradient}></div>
        </div>
        <p className={styles.para}>
          Welcome to our business overview! Our company is a domestic business
          that operates within the borders of our home country. Here's a bit
          more information about us:
        </p>
        <p className={styles.para}>
          Our company is dedicated to providing high-quality products and
          services to our customers within our country. We have a strong focus
          on understanding the needs and preferences of our domestic market and
          tailoring our offerings to meet those needs
        </p>
      </div>
    </div>
  );
};

export default index;
